<template>
  <div class="box-graphics">
    <div class="d-flex justify-end">
      <seguimiento class="ml-5" @reloadProductivityCharts="reloadCharts()"></seguimiento>
    </div>
    <h3 class="text-center tway-header-1">Productividad</h3>
    <!-- LVT -->
    <v-row class="d-flex justify-space-around" style="display: none">
      <div class="graphics-container" style="display: none">
        <div>
          <div>
            <div class="mx-4 mt-1 pt-2 tway-violet--text">
              <p>
                LTV Cliente Digital
                <ToolTips
                  width="300"
                  description="Valor neto de los ingresos que genera un cliente digital al negocio durante su tiempo de permanencia como cliente."
                />
              </p>
              <div class="text-sum">Total LTV mes anterior (1): {{ monetaryUnit + lastMonthLTV }}</div>
              <div class="text-sum">Total LTV mes actual (0): {{ monetaryUnit + thisMonthLTV }}</div>
            </div>
            <div>
              <VueApexCharts
                type="heatmap"
                height="300"
                :series="chartDigitalClient.series"
                :options="chartDigitalClient.options"
              />
            </div>
          </div>
        </div>
      </div>
    </v-row>
    <!-- ROI & ESCALABILIDAD -->
    <v-row class="d-flex justify-space-around">
      <!-- ESCALABILIDAD -->
      <div class="graphics-container-duo" style="width: 62%">
        <div class="tway-violet--text">Escalabilidad</div>
        <div>
          <div class="inside-box">
            <div>
              <div class="mx-4 mt-1 pt-2 tway-violet--text">
                <p>
                  Servicio
                  <ToolTips
                    width="320"
                    description="Relación entre costo por servicio adicional y cantidad de servicios vendidos para crecer en magnitud sin perder calidad."
                    formula="(Costo de servicio / Número de servicios vendidos) x100."
                    objective="0 es mayor grado y 100 es menor grado"
                  />
                </p>
              </div>
              <div>
                <VueApexCharts
                  :series="chartService.series"
                  :options="chartService.chartOptions"
                  height="280"
                  type="radialBar"
                />
              </div>
            </div>
          </div>
          <div class="inside-box">
            <div>
              <div class="mx-4 mt-1 pt-2 tway-violet--text">
                <p>
                  Proceso
                  <ToolTips
                    width="320"
                    description="Relación entre costo de un proceso adicional y la cantidad de procesos realizados para crecer en magnitud sin perder eficiencia y productividad."
                    formula="(Costo de proceso / Proceso realizados) x100."
                    objective="0 es mayor grado y 100 es menor grado"
                  />
                </p>
              </div>
              <div>
                <VueApexCharts
                  :series="chartProcess.series"
                  :options="chartProcess.chartOptions"
                  height="280"
                  type="radialBar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- RETORNO INVERSION -->
      <div class="graphics-container" style="width: 35%">
        <div class="tway-violet--text">
          <p>
            Retorno de la Inversión (ROI)
            <ToolTips
              width="300"
              description="Beneficio final que recibirá una empresa como resultado de invertir cierta cantidad de recursos en proyectos orientadosa aumentar la productividad."
            />
          </p>
        </div>

        <div>
          <div class="mt-2">
            <VueApexCharts
              ref="chartInvestment"
              :series="chartInvestment.series"
              :options="chartInvestment.options"
              height="250"
              type="area"
            />
          </div>
        </div>
      </div>
    </v-row>
    <!-- OPERACIONES & METOLODOLOGIAS AGILES -->
    <v-row class="d-flex justify-space-around margin-fix">
      <!-- Operaciones - Digitalización cadena de valor -->
      <div class="graphics-container" style="width: 35%">
        <div class="tway-violet--text">Operaciones</div>

        <div>
          <div class="mt-1 ms-4 pt-2 tway-violet--text text-heigth">
            <p>
              Digitalización de Cadena de Valor
              <ToolTips
                width="270"
                description="Capacidades digitales instaladas, como plataformas, infraestructura digital o soluciones basadas en datos, que entregan valor en términos de ingresos y productividad a lo largo de toda la cadena de valor."
              />
            </p>
          </div>
          <div class="mt-2">
            <VueApexCharts
              :series="chartChainValue.series"
              :options="chartChainValue.chartOptions"
              height="300"
              type="radialBar"
            />
          </div>
        </div>
      </div>
      <!-- AGILES -->
      <div class="graphics-container-duo" style="width: 62%">
        <div class="tway-violet--text">Metodologías Ágiles</div>
        <div>
          <div>
            <div class="mx-4 mt-1 pt-2 tway-violet--text text-heigth">
              <p>
                Clientes Generados
                <ToolTips description="Descripción por redactar" />
              </p>
            </div>
            <div class="mt-2">
              <VueApexCharts
                :series="chartGeneratedClients.series"
                :options="chartGeneratedClients.chartOptions"
                height="300"
                type="radialBar"
              />
            </div>
          </div>
          <div>
            <div class="mx-4 mt-1 pt-2 tway-violet--text text-heigth">
              <p>
                Mejora Time to Market
                <ToolTips
                  width="330"
                  description="Reducción en el tiempo de comercialización de un producto o servicio a causa de implementación de metodologías ágiles."
                />
              </p>
            </div>
            <div class="mt-2">
              <VueApexCharts
                :series="chartMarket.series"
                :options="chartMarket.chartOptions"
                height="300"
                type="radialBar"
              />
            </div>
          </div>
        </div>
      </div>
    </v-row>

    <!-- CANAL DIGITAL  -->
    <div class="graphics-container-cuatriple padding-fix">
      <div class="tway-violet--text">Canal Digital</div>
      <div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Tasa de Venta
              <ToolTips
                width="300"
                description="Cantidad de usuarios que realiza una compra online."
                formula="(Cantidad de usuarios del canal digital) / (Cantidad de usuarios que compraron)"
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartDigitalChannel"
              :series="chartDigitalChannel.series"
              :options="chartDigitalChannel.options"
              width="500"
              height="300"
              type="area"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Clientes que Regresan por Acción Digital (Win Back)
              <ToolTips
                width="295"
                description="Cantidad de ex-clientes o usuarios digitales inactivos que se recuperan como clientes o usuarios activos a causa de acciones digitales."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartWinBack"
              :series="chartWinBack.series"
              :options="chartWinBack.options"
              width="500"
              height="300"
              type="area"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Talentos Digitales -->
    <div class="graphics-container-duo">
      <div class="tway-violet--text">Talentos Digitales</div>
      <div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Rendimiento
              <ToolTips
                width="300"
                description="Mide de forma periódica, cuantitativamente y cualitativamente, lo eficaz que es un talento con competencias digitales en términos de tiempo y costos."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartYield.series"
              :options="chartYield.chartOptions"
              height="300"
              type="radialBar"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Rotación
              <ToolTips
                width="300"
                description="Cantidad de talentos digitales nuevos que reemplazan a los que se retiran voluntaria o involuntariamente de una empresa."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartRotation.series"
              :options="chartRotation.chartOptions"
              height="300"
              type="radialBar"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Seguimiento from "../../components/pmo/productivity/seguimiento.vue";
//import roductivityService from "../../services/pmo/productivity/productivityService";
import LtvService from "../../services/pmo/ltv/ltvService";
import BudgetService from "../../services/pmo/budget/budgetService";
import VueApexCharts from "vue-apexcharts";
import ToolTips from "../../components/pmo/utils/ToolTips";
import productivityService from "../../services/pmo/productivity/productivityService";

export default {
  name: "Engagement",
  components: {
    Seguimiento,
    VueApexCharts,
    ToolTips,
  },
  data() {
    return {
      chartInvestment: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#7F7F7F"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7F7F7F",
              },
              labels: { style: { colors: "#7F7F7F" } },
              title: {
                text: "%",
                style: { color: "#7F7F7F" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      chartService: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                      }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319D5"],
            type: "solid",
          },
          labels: [""],
          stroke: { lineCap: "round" },
        },
      },
      chartProcess: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                      }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#16C62E"],
            type: "solid",
          },
          labels: [""],
          stroke: { lineCap: "round" },
        },
      },

      chartChainValue: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                      }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319D5"],
            type: "solid",
          },
          labels: [""],
          stroke: { lineCap: "round" },
        },
      },

      chartDigitalClient: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "heatmap",
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#7F7F7F", "#16C62E", "#7319D5", "#B2B2B2", "#4A148C", "#AEEA00"].reverse(),
          xaxis: {
            type: "category",
            name: "Hola",
            categories: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"],
          },
          title: {
            text: undefined,
          },
          grid: {
            padding: {
              right: 20,
            },
          },
        },
      },

      chartGeneratedClients: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                      }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#16C62E"],
            type: "solid",
          },
          labels: [""],
          stroke: { lineCap: "round" },
        },
      },
      chartMarket: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                      }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7F7F7F"],
            type: "solid",
          },
          labels: [""],
          stroke: { lineCap: "round" },
        },
      },

      chartCostReduction: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                      }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7319D5"],
          },
          labels: [""],
        },
      },
      chartTaskReduction: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                      }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7F7F7F"],
          },
          labels: [""],
        },
      },

      chartDigitalChannel: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#7319D5"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7319D5",
              },
              labels: { style: { colors: "#7319D5" } },
              title: {
                text: "Cantidad de Usuarios",
                style: { color: "#7319D5" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      chartWinBack: {
        series: [],
        options: {
          chart: {
            height: 450,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
          },
          colors: ["#16C62E"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#16C62E",
              },
              labels: { style: { colors: "#16C62E" } },
              title: {
                text: "Cantidad de Usuarios",
                style: { color: "#16C62E" },
              },
              min: 0,
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },

      chartYield: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            offsetY: 0,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                      }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319D5"],
            type: "solid",
          },
          labels: [""],
          stroke: { lineCap: "round" },
        },
      },
      chartRotation: {
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
            fontFamily: "Josefin Sans, Arial, sans-serif",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                      }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#16C62E"],
            type: "solid",
          },
          labels: [""],
          stroke: { lineCap: "round" },
        },
      },
      thisMonthLTV: "",
      lastMonthLTV: "",
      monetaryUnit: "",
    };
  },

  methods: {
    reloadCharts() {
      this.callService();
    },
    async callService() {
      await LtvService.getChartLtv(this.project)
        .then((data) => {
          this.chartDigitalClient.series = data.serie;
        })
        .catch((err) => console.dir(err));
      await productivityService
        .getChartInvestment(this.project)
        .then((data) => {
          this.chartInvestment.series = data.investment;
          this.$refs.chartInvestment.updateOptions({
            xaxis: {
              categories: data.investment[0].categories,
            },
          });
        })
        .catch((err) => console.dir(err));
      await productivityService
        .getChartScalability(this.project)
        .then((data) => {
          this.chartService.series = data.service.series;
          this.chartProcess.series = data.process.series;
        })
        .catch((err) => console.dir(err));
      await productivityService
        .getChartAgileMethodology(this.project)
        .then((data) => {
          this.chartGeneratedClients.series = data.client.series;
          this.chartMarket.series = data.market.series;
        })
        .catch((err) => console.dir(err));
      await productivityService
        .getChartOperationReduction(this.project)
        .then((data) => {
          this.chartChainValue.series = data.chain.series;
        })
        .catch((err) => console.dir(err));

      await productivityService
        .getChartTalent(this.project)
        .then((data) => {
          this.chartYield.series = data.yield.series;
          this.chartRotation.series = data.rotation.series;
        })
        .catch((err) => console.dir(err));
      await productivityService
        .getChartDigitalChannel(this.project)
        .then((data) => {
          this.chartDigitalChannel.series = data.channel;
          this.chartWinBack.series = data.winback;
          this.$refs.chartDigitalChannel.updateOptions({
            xaxis: {
              categories: data.channel[0].categories,
            },
          });
          this.$refs.chartWinBack.updateOptions({
            xaxis: {
              categories: data.winback[0].categories,
            },
          });
        })
        .catch((err) => console.dir(err));
      await LtvService.getLtvSum(this.project)
        .then((data) => {
          this.thisMonthLTV = data.now;
          this.lastMonthLTV = data.last;
        })
        .catch((err) => {
          console.dir(err);
        });
      await BudgetService.getTableCostCenter(this.project)
        .then((data) => {
          this.monetaryUnit = data.monetaryUnit;
        })
        .catch((err) => {
          console.dir(err);
        });
    },
  },
  async mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.callService();
  },
};
</script>

<style lang="scss" scoped>
.box-graphics {
  max-width: 1400px;
  min-width: 1100px;
}

.graphics {
  display: flex;
  flex-direction: column;
}

.shrink {
  width: 150px;
}

.bar {
  width: 350px;
}

.graphics-container {
  display: flex;
  flex-direction: column;
  align-items: space-evenly;
  width: 100%;
  padding: 30px 20px 0px 20px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  margin: 25px 0px 25px 0px;
  div {
    display: flex;
    flex-direction: column;
  }
  p {
    font-size: 16px;
  }
}

.graphics-container-duo,
.graphics-container-triple,
.graphics-container-cuatriple {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  padding: 30px 20px 0px 20px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  margin: 25px 0px 25px 0px;
  div {
    display: flex;
    flex-direction: row;
  }
  p {
    font-size: 16px;
  }
}

.graphics-container-duo {
  div {
    div {
      display: flex;
      flex-direction: column;
      width: 46%;

      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}

.graphics-container-triple {
  div {
    div {
      width: 33%;
      display: flex;
      flex-direction: column;
      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}
.graphics-container-cuatriple {
  div {
    div {
      width: 50%;
      display: flex;
      flex-direction: column;
      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}
.text-sum {
  font-size: 0.8em;
  color: #7f7f7f;
}

.box-container {
  display: flex;
  align-items: stretch;
  width: inherit;
}
.box-container > .inside-box > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}
.inside-box > div > div {
  padding-top: auto;
  margin-top: auto;
}
.text-heigth {
  height: 20px;
}
.padding-fix {
  padding-bottom: 20px;
}
.margin-fix {
  margin-top: 0;
}
</style>
